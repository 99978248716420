<template>
  <div class=" mx-auto">
      <div>
        <Loading :isLoading="this.isLoading" />
        <div style="display: flex;margin:0 auto; margin-bottom:20px;">
          <div class="div_centrado" style="margin:0 auto;background:white;width:95%;height:100%;margin-top:50px;border-radius:14px; text-align: center;box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);padding-left:25px;padding-right:25px;">
            <div class="flex" style="margin-top:40px;">
              <div class="w-full">
                <p class="title">
                  Carga de Documentos
                </p>
                <h4 style="text-align:center;" class="steps">Paso 7 de 10</h4>
              </div>
            </div>
            <div class="flex" style="margin-top:0px;">
              <div class="w-full">
                <p style="text-align:center;padding-left: 40px;padding-right: 40px;" class="description">
                  A continuación realiza la carga de los documentos listados en formato .pdf, .png o .jpeg., para concluir con tu proceso de registro.
                </p>
              </div>
            </div>
            <div style="margin-top:20px;">
              <table class="width200">
                <thead>
                <tr>
                  <th>Documento</th>
                  <th>Estatus</th>
                  <th>Nombre del archivo</th>
                  <th></th>
                </tr>
                </thead>
                <tbody>
                  <tr style="vertical-align:top;">
                    <td style="font-size:16px;font-family:Roboto;font-weight: 400;">Identificación</td>
                    <td style="height:40px;"><Check v-show="loadCheckID" /></td>
                    <td style="vertical-align:top;">
                      <table class="table-scroll2 subtableid">
                        <thead>
                        </thead>
                        <tbody class="">
                          <tr v-for="item in documentsArray.identifications" :key="item.id">
                            <td style="vertical-align:top;padding-top:0;padding-left:0;"><div class="titles"><i>{{item.name}}</i></div></td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                    <td style="">
                      <input type="button" v-show="!loadCheckID" class="btnMainNoResponsive" value="Completar registro" v-on:click="goToValidate()" />
                      <p v-show="loadCheckID" class="textMati" style="">Documento obtenido <br/>de la verificación</p>
                    </td>
                  </tr>
                  <tr>
                    <td style="font-size:16px;font-family:Roboto;font-weight: 400;">
                      C.U.R.P. <span class="text-red-500">*</span>
                      <p class="text-blue-600 visited:text-purple-600" style="font-size:12px;font-family:Roboto;font-weight: 300;"><a href="https://www.gob.mx/curp/" target="_blank">Descargar aquí.</a> </p>
                    </td>
                    <td style="height:40px;"><Check v-show="loadCheckCURP" /></td>
                    <td style=""><div class="titles"><i style="width:100%;">{{ curp }}</i></div></td>
                    <td style="">
                      <input type="button" class="btnMainNoResponsive" v-show="curp==''" value="Cargar archivo" v-on:click="getFileCurp()" />
                      <button v-show="curp==''" v-on:click="getFileCurp()" class="btnLoadMobile" style="text-decoration:none;"><img height="40" width="40" src="@/assets/Cuenta/cargar.svg" style="width:28px;margin-left:1px;"></button>
                      <input type="button" class="btnDeleteDocument btnDeleteNoResponsive" v-show="curp!=''" value="Eliminar" v-on:click="deleteDocument(0, 'CURP', curp)" />
                      <button v-show="curp!=''" v-on:click="deleteDocument(0, 'CURP', curp)" class="btnDeleteMobile" style="text-decoration:none;"><img src="@/assets/Cuenta/eliminar.svg" style="width:28px;margin-left:1px;"></button>
                      <div style='height: 0px;width:0px; overflow:hidden;'><input id="upfileCurp" type="file" accept=".pdf,.png,.PNG,.jpeg" @change="loadDocument($event, 'CURP')"/></div>
                    </td>
                  </tr>
                  <tr>
                    <td style="font-size:16px;font-family:Roboto;font-weight: 400;">
                      Comprobante de Domicilio <span class="text-red-500">*</span>
                      <p style="font-size:12px;font-family:Roboto;font-weight: 300;">A nombre del socio solicitante con una antigüedad no mayor a 3 meses (Luz, agua, teléfono, Estado de cuenta bancario).</p>
                    </td>
                    <td style="height:40px;"><Check v-show="loadCheckProofResidency" /></td>
                    <td style=""><div class="titles"><i>{{ proofResidency }}</i></div></td>
                    <td style="height:50px;">
                      <input type="button" class="btnMainNoResponsive" v-show="proofResidency==''" value="Cargar archivo" v-on:click="getFileProofResidency()" />
                      <button v-show="proofResidency==''" v-on:click="getFileProofResidency()" class="btnLoadMobile" style="text-decoration:none;"><img height="40" width="40" src="@/assets/Cuenta/cargar.svg" style="width:28px;margin-left:1px;"></button>
                      <input type="button" class="btnDeleteDocument btnDeleteNoResponsive" v-show="proofResidency!=''" value="Eliminar" v-on:click="deleteDocument(0, 'CD', proofResidency)" />
                      <button v-show="proofResidency!=''" v-on:click="deleteDocument(0, 'CD', proofResidency)" class="btnDeleteMobile" style="text-decoration:none;"><img height="40" width="40" src="@/assets/Cuenta/eliminar.svg" style="width:28px;margin-left:1px;"></button>
                      <div style='height: 0px;width:0px; overflow:hidden;'><input id="upfileProofResidency" type="file" accept=".pdf,.png,.PNG,.jpeg"   v-on:change="loadDocument($event, 'CD')"  /></div>
                    </td>
                  </tr>
                  <tr class="trTable">
                    <td style="vertical-align:top;height:40px;font-size:16px;font-family:Roboto;font-weight: 400;">
                      Comprobantes de Ingresos
                      <p style="font-size:12px;font-family:Roboto;font-weight: 300;">De los últimos tres meses.</p>
                    </td>
                    <td style="vertical-align:top;height:80px;height:40px;"><Check v-show="loadCheckProofIncome" /></td>
                    
                    <td style="vertical-align:top;" colspan="2">
                      <table class="table-scroll small-first-col subtable">
                        <thead>
                        </thead>
                        <tbody class="body-half-screen">
                          <tr v-for="item in documentsArray.proofIncomes" :key="item.id">
                            <td class="tdTable" style="">
                              <div class="grid grid-cols-1">
                                <div class="titlesProofIncome">
                                  <i>{{item.name}}</i>
                                </div>
                                <div>
                                  <button v-on:click="deleteDocument(item.id, 'CI', item.name)" class="btnDeleteMobile" style="text-decoration:none;"><img height="40" width="40" src="@/assets/Cuenta/eliminar.svg" style="width:28px;margin-left:1px;"></button>
                                </div>
                              </div>
                              <!-- <input type="button" class="btnDeleteDocument btnDeleteResponsive" value="Eliminar" v-on:click="deleteDocument(item.id, 'CI', item.name)" /> -->
                              
                            </td>
                            <td class= "tdButton" style="padding-left:0;"><input type="button" class="btnDeleteNoResponsive" value="Eliminar" v-on:click="deleteDocument(item.id, 'CI', item.name)" /></td>
                          </tr>
                          <input type="button" class="btnMainProofIncomeNoResponsive" value="Cargar más archivos" 
                          :disabled="!letPushProofIncome" v-bind:style="{opacity: letPushProofIncome ? activeColor : activeColor2}" 
                          v-on:click="getFileProofIncome()" style="" />
                          <button :disabled="!letPushProofIncome" v-bind:style="{opacity: letPushProofIncome ? activeColor : activeColor2}" v-on:click="getFileProofIncome()" class="btnLoadMobile" style="text-decoration:none;"><img height="40" width="40" src="@/assets/Cuenta/cargar.svg" style="width:28px;margin-left:1px;"></button>
                          <div style='height:0px;width:0px; overflow:hidden;'><input id="upfileProofIncome" type="file" multiple="true" accept=".pdf,.png,.PNG,.jpeg" 
                          @change="loadDocument($event, 'CI')" style="float:right" /></div>
                        </tbody>
                      </table>
                    </td>
                  </tr>
                  <tr>
                    <td style="vertical-align:top;height:40px;font-size:16px;font-family:Roboto;font-weight: 400;">
                      Estado de Cuenta
                      <p style="font-size:12px;font-family:Roboto;font-weight: 300;">Del último mes.</p>
                    </td>
                    <td style="height:40px;"><Check v-show="loadCheckFinancialStatement" /></td>
                    <td style=""><div class="titles"><i>{{ financialStatement }}</i></div></td>
                    <td style="">
                      <input type="button" class="btnMainNoResponsive" v-show="financialStatement==''" value="Cargar archivo" v-on:click="getFileFinancialStatement()" />
                      <button v-show="financialStatement==''" v-on:click="getFileFinancialStatement()" class="btnLoadMobile" style="text-decoration:none;"><img height="40" width="40" src="@/assets/Cuenta/cargar.svg" style="width:28px;margin-left:1px;"></button>
                      <input type="button" class="btnDeleteDocument btnDeleteNoResponsive" v-show="financialStatement!=''" value="Eliminar" v-on:click="deleteDocument(0, 'EC', financialStatement)" />
                      <button v-show="financialStatement!=''" v-on:click="deleteDocument(0, 'EC', financialStatement)" class="btnDeleteMobile" style="text-decoration:none;"><img src="@/assets/Cuenta/eliminar.svg" style="width:28px;margin-left:1px;"></button>
                      <div style='height: 0px;width:0px; overflow:hidden;'><input id="upfileFinancialStatement" type="file" accept=".pdf,.png,.PNG,.jpeg" @change="loadDocument($event, 'EC')"/></div>
                    </td>
                  </tr>
                  <tr class="trRFC">
                    <td colspan="4" style="vertical-align:bottom;color:#726659;font-size:16px;font-family:Roboto;font-weight:700;">Agrega tu R.F.C. o Constancia de situación Fiscal.</td>
                  </tr>
                  <tr style="">
                    <td style="font-size:16px;font-family:Roboto;font-weight: 400;">
                      R.F.C. o Constancia de situación Fiscal
                      <p class="text-blue-600 visited:text-purple-600" style="font-size:12px;font-family:Roboto;font-weight: 300;"><a href="https://www.sat.gob.mx/aplicacion/login/53027/genera-tu-constancia-de-situacion-fiscal" target="_blank">Descargar aquí.</a> </p>
                    </td>
                    <td style="height:40px;"><Check v-show="loadCheckRFC" /></td>
                    <td style=""><div class="titles"><i>{{ rfc }}</i></div></td>
                    <td style="">
                      <input type="button" class="btnMainNoResponsive" v-show="rfc==''" value="Cargar archivo" v-on:click="getFileRFC()" />
                      <button v-show="rfc==''" v-on:click="getFileRFC()" class="btnLoadMobile" style="text-decoration:none;"><img height="40" width="40" src="@/assets/Cuenta/cargar.svg" style="width:28px;margin-left:1px;"></button>
                      <input type="button" class="btnDeleteDocument btnDeleteNoResponsive" v-show="rfc!=''" value="Eliminar" v-on:click="deleteDocument(0, 'RFCFIEL', rfc)" />
                      <button v-show="rfc!=''" v-on:click="deleteDocument(0, 'RFCFIEL', rfc)" class="btnDeleteMobile" style="text-decoration:none;"><img height="40" width="40" src="@/assets/Cuenta/eliminar.svg" style="width:28px;margin-left:1px;"></button>
                      <div style='height: 0px;width:0px; overflow:hidden;'><input id="upfileRFC" type="file" accept=".pdf,.png,.PNG,.jpeg" @change="loadDocument($event, 'RFCFIEL')"/></div>
                    </td>
                  </tr>
                </tbody>
              </table>
              <div v-if="requestFinish" style="margin-bottom:20px;">
                <div class="flex">
                  <div class="w-full">
                    <input type="button" class="next_button" value="Regresar" v-on:click="goToMainPage()"/>
                  </div>
                </div>
              </div>
              <div v-if="!requestFinish" style="margin-bottom:20px;">
                <div class="flex">
                  <div class="w-full">
                    <input type="button" class="next_button" value="Cargar más tarde" v-on:click="goToMainPage()"/>
                  </div>
                </div>
                <div class="flex mt-8">
                  <div class="w-full">
                    <input type="button" class="end_process" value="Continuar proceso de registro"
                      :disabled="!isReady" v-bind:style="{opacity: isReady ? activeColor : activeColor2}"
                      v-on:click="endProcess()" id="btnEndProcess" />
                  </div>
                </div>
                <div class="flex mt-5">
                  <div class="w-full">
                    <input type="button" class="back_button" value="Atrás"
                      v-on:click="backReedirect()" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    <!--<link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/3.4.1/css/bootstrap.min.css">-->
    <!-- <link href="https://unpkg.com/tailwindcss@^1.0/dist/tailwind.min.css" rel="stylesheet"> -->
    <link href='https://fonts.googleapis.com/css?family=Roboto' rel='stylesheet'>
  </div>
</template>

<script>
import('@/assets/css/stylesheet.css')
import MenuLateral from "@/components/Cuenta/MenuLateral.vue";
import Menu from '@/components/Menu.vue'
import Loading from '@/components/Loading/VueLoading.vue'
import Check from '@/components/Loading/VueCheck.vue'
import { getAdmissionRequestOrCreate,getAdmissionRequest,getDataVerificationByProcess,getDataVerificationMati, saveRequest } from '@/api/user'
import Swal from "sweetalert2";
import {  getTokenDecoden } from "@/helpers/tokenauth";
import AdmissionRequest from '@/classes/AdmissionRequest';

export default {
     data() {
      return {
        activeColor: '1',
        activeColor2: '0.4',
        personId: "",
        ccapi: process.env.VUE_APP_CCAPI,
        multiApi:process.env.VUE_APP_MULTIAPI,
        isLoading: false,
        loadCheckID: false,
        loadCheckCURP: false,
        loadCheckProofResidency: false,
        loadCheckProofIncome: false,
        loadCheckFinancialStatement: false,
        loadCheckRFC: false,
        curp: "",
        proofResidency: "",
        proofIncomes: [],
        rfc: "",
        documentsArray: {
          identifications: [],
          documents: [],
          proofIncomes: []
        },
        financialStatement: "",
        isFromMenu: false,
        member_id: "",
        mati_identity_id: "",
        isReady: false,
        existBeneficiaries: false,

        user_id:null,
        admission_id:"",
        admission_status: null,
        user_is_validated:false,
        data_verification_id: null,
        infoDocuments:null,
        message:null,
        nameDocumentsArray:[],
        base64Frontal:"",
        base64Trasera:"",
        extensionFrontal:"",
        extensionTrasera:"",
        client_classification:"",
        requestFinish: false,
        admissionRequestObject: new AdmissionRequest()
      };
    },
    name: 'CargaDocumentosss',
    components: {
      Menu,
      Loading,
      Check,
      MenuLateral
    },
    async mounted() {
    },
    async beforeMount() {
      Array.prototype.max = function() {
        return Math.max.apply(null, this);
      };
      await this.loadInfo();

      this.getRouterParams();
      await this.getAdmissionRequest();
    },
    computed: {
      letPushProofIncome(){
        if (this.documentsArray.proofIncomes.length >= 18) {
          return false;
        }
        return true;
      },
    },
    methods:{
      // redirectCorrectPage(message, redirect){
      //   Swal.fire({
      //     title: message,
      //     showDenyButton: true,
      //     confirmButtonText: `Si`,
      //     denyButtonText: `No`,
      //   }).then((result) => {
      //     if (result.isConfirmed) {
      //       window.location.href = redirect
      //     } else if (result.isDenied) {
      //       window.location.href = "/cuenta/principal"
      //     }
      //   })
      // },
      getRouterParams() {
        this.isFromMenu = this.$route.params.fromMenu == "1" ? true : false;
      },
      checkDocumentsLoaded() {
        return this.isReady;
      },
      async getDocuments() {
        this.isLoading = true;
        return await this.axios.get(this.ccapi + `/${process.env.VUE_APP_BUSINESS_FONCABSA}/admission_request/download_all_documents`, {
          params:{
            token_auth: sessionStorage.getItem("login"),
            admission_request_id: this.admission_id,
            remote_folder:"documentos_alta_socios"
          }
        }, { 'Content-Type': 'application/json' })
        .then(response => {
          this.infoDocuments= response.data.response.documents;
          // console.log("infoDocuments: ",this.infoDocuments);
          this.setDocumentSelected(this.infoDocuments, true);
          this.havePendingDocuments(this.infoDocuments.length);
        }).catch(error => {
              Swal.fire({
                title: "Aviso",
                text: "Se presento un error al descargar los documentos.",
                icon: "error",
                confirmButtonColor: '#FEB72B',
              });
              // window.location.href = "/cuenta/principal";
              this.$router.push({path:"/cuenta/principal"});
        });
      },
      getFileCurp() {
        document.getElementById("upfileCurp").value = "";
        Swal.fire({
            title: "Aviso",
            text: '¿Desea cargar la CURP? ',
            icon: "question",
            showCancelButton: true,
            reverseButtons:true,
            confirmButtonColor: '#FEB72B',
            cancelButtonColor: '#FEB72B',
            confirmButtonText: "SI",
            cancelButtonText: "NO"
        })
        .then((result) => {
          if (result.value) {
            document.getElementById("upfileCurp").click();
          }
        });
      },
      getFileProofResidency() {
        document.getElementById("upfileProofResidency").value = "";
        Swal.fire({
            title: "Aviso",
            text: '¿Desea cargar el Comprobante de Domicilio? ',
            icon: "question",
            showCancelButton: true,
            reverseButtons:true,
            confirmButtonColor: '#FEB72B',
            cancelButtonColor: '#FEB72B',
            confirmButtonText: "SI",
            cancelButtonText: "NO"
        })
        .then((result) => {
          if (result.value) {
            document.getElementById("upfileProofResidency").click();
          }
        });
      },
      getFileProofIncome() {
        document.getElementById("upfileProofIncome").value = "";
        Swal.fire({
            title: "Aviso",
            text: '¿Desea cargar un Comprobante de Ingresos? ',
            icon: "question",
            showCancelButton: true,
            reverseButtons:true,

            confirmButtonColor: '#FEB72B',
            cancelButtonColor: '#FEB72B',
            confirmButtonText: "SI",
            cancelButtonText: "NO"
        })
        .then((result) => {
          if (result.value) {
            document.getElementById("upfileProofIncome").click();
          }
        });
      },
      getFileFinancialStatement() {
        document.getElementById("upfileFinancialStatement").value = "";
        Swal.fire({
            title: "Aviso",
            text: '¿Desea cargar el Estado de Cuenta? ',
            icon: "question",
            reverseButtons:true,
            showCancelButton: true,
            confirmButtonColor: '#FEB72B',
            cancelButtonColor: '#FEB72B',
            confirmButtonText: "SI",
            cancelButtonText: "NO"
        })
        .then((result) => {
          if (result.value) {
            document.getElementById("upfileFinancialStatement").click();
          }
        });
      },
      getFileRFC() {
        document.getElementById("upfileRFC").value ="";
        Swal.fire({
            title: "Aviso",
            text: '¿Desea cargar el RFC? ',
            icon: "question",
            showCancelButton: true,
            reverseButtons:true,
            confirmButtonColor: '#FEB72B',
            cancelButtonColor: '#FEB72B',
            confirmButtonText: "SI",
            cancelButtonText: "NO"
        })
        .then((result) => {
          if (result.value) {
            document.getElementById("upfileRFC").click();
          }
        });
      },
      existDocument(documents, newDocument) {
        let indexDocuments = documents.findIndex(d => d.name === newDocument);
        if (indexDocuments != -1) {
          return true;
        }
        return false;
      },
      loadDocument(event, type) {
        let files = event.target.files;
        this.isLoading = true;
        let fileBase64 = "";
        let filename = files[0].name;
        let extension = files[0].type.split('/')[1];
        const fileReader = new FileReader();
        fileReader.onerror = this.errorHandler;
        let fileNameWitUnderscore = filename.replace(/\s/g, '_');
        // console.log("this.nameDocumentsArray: ",this.nameDocumentsArray);
        // console.log("fileNameWitUnderscore: ",fileNameWitUnderscore);

        if(this.nameDocumentsArray.length > 0){
          if (this.existDocument(this.nameDocumentsArray, fileNameWitUnderscore)) {
            this.isLoading = false;
            Swal.fire({
              title: "Aviso",
              text: "El archivo seleccionado ya ha sido cargado",
              icon: "error",
              confirmButtonColor: '#FEB72B',
            });
            return;
          }
        }
        let documents_list=[];
        fileReader.addEventListener('load', () => {
          fileBase64 = fileReader.result.split(',')[1];
          event.target.value = null;
          //this.save(fileBase64, filename.split('.')[0].replace(/\s/g, '_'), extension, type);
          documents_list.push({
            name: filename.split('.')[0].replace(/\s/g, '_'),
            extension: extension,
            abbreviation: type,
            admission_request_id: this.admission_id,
            remote_folder: "documentos_alta_socios",
            file: fileBase64
          });
          let self = this;
          this.save(documents_list).then((response) => {
            self.isLoading = false;
          });
        })
        fileReader.readAsDataURL(files[0]);

      },
      errorHandler(evt) {
        let mensaje ="";
        switch(evt.target.error.code) {
          case evt.target.error.NOT_FOUND_ERR:
            mensaje = "Archivo no encontrado.";
            break;
          case evt.target.error.NOT_READABLE_ERR:
            mensaje = "El archivo no es leible."
            break;
          case evt.target.error.ABORT_ERR:
            break; // noop
          default:
            mensaje = "Ocurrió un error en la lectura del archivo.";
        };
        Swal.fire({
          title: "Aviso",
          text: mensaje,
          icon: "error",
          confirmButtonColor: '#FEB72B',
        });
      },
      deleteDocument(id, type, name) {
        Swal.fire({
            title: "Aviso",
            text: '¿Desea eliminar el siguiente documento? ',
            html: `<p>¿Desea eliminar el siguiente documento?<br/>
                    <span style="font-weight: bold; color:red">${name}</span>
                   </p>`,
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: '#FEB72B',
            cancelButtonColor: '#FEB72B',
            confirmButtonText: "SI",
            cancelButtonText: "NO"
        })
        .then((result) => {
          if (result.value) {
            this.isLoading = true;
            let index = -1;
            let finalDocument = {}
            if (type == "CI") {
              index = this.documentsArray.proofIncomes.findIndex(d => d.id === id);
              if (index > -1) {
                finalDocument = this.getFinalDocumentDelete(this.documentsArray.proofIncomes[index]);
              }
            } else {

              index = this.documentsArray.documents.findIndex(d => d.typeDocument === type);
              if (index > -1) {
                finalDocument = this.getFinalDocumentDelete(this.documentsArray.documents[index]);
              }
            }

            this.axios.post(this.ccapi + `/${process.env.VUE_APP_BUSINESS_FONCABSA}/admission_request/delete_document`, {
              token_auth: sessionStorage.getItem("login"),
              document_id: finalDocument[0].document_id
            },{ 'Content-Type': 'application/json'})
            .then(response => {
                this.cleanControls(type);
                this.validate();
                Swal.fire({
                    title: "Aviso",
                    text: "El archivo ha sido eliminado",
                    icon: "success",
                    confirmButtonColor: '#FEB72B',
                });
              // console.log("type: ",type);
              // console.log("nameDocumentsArray type: ",this.nameDocumentsArray);

              const i = this.nameDocumentsArray.findIndex( element => element.type == type);
              // console.log("i: ",i);
              // console.log("this.nameDocumentsArray.lenght",this.nameDocumentsArray.length);
              // console.log("this.nameDocumentsArray.lenght",this.documentsArray.length);

              this.nameDocumentsArray.splice(i,1);
              //Object.values(this.documentsArray).splice(index,1);
              let dt1 =this.nameDocumentsArray;
              if (type == "CI") {
                this.documentsArray.proofIncomes.splice(index,1);
              } else {
                this.documentsArray.documents.splice(index,1);
              }
              let dt = this.documentsArray;
              // console.log("this.nameDocumentsArray.lenght",this.nameDocumentsArray.length);
              // console.log("this.nameDocumentsArray.lenght",this.documentsArray.length);
            }).catch( error => {
              console.log("Error al eliminar: ",error);
              Swal.fire({
                title: "Aviso",
                text: "Se presento un error al eliminar el archivo",
                icon: "error",
                confirmButtonColor: '#FEB72B',
              });
            })
            .finally(() => {
              this.isLoading = false;
            });
          }
        });
      },
      getMaxIdDocuments(typeDocument) {
        let idArray = []
        if (typeDocument != "CI" && typeDocument != "IO") {
          if(this.documentsArray.documents.length > 0) {
            for (let i = 0; i < this.documentsArray.documents.length; i++) {
              idArray.push(this.documentsArray.documents[i].id);
            }
          } else {
              return 0
          }
        } else if (typeDocument == "CI") {
          if (this.documentsArray.proofIncomes.length > 0) {
            for (let i = 0; i < this.documentsArray.proofIncomes.length; i++) {
              idArray.push(this.documentsArray.proofIncomes[i].id);
            }
          } else {
            return 0
          }
        } else if (typeDocument == "IO") {
          if (this.documentsArray.identifications.length > 0) {
            for (let i = 0; i < this.documentsArray.identifications.length; i++) {
              idArray.push(this.documentsArray.identifications[i].id);
            }
          } else {
            return 0
          }
        }
        
        let max = idArray.max()
        return max
      },
      cleanControls(abbreviation) {
        switch (abbreviation) {
          case "CURP":
            this.curp = "";
            this.loadCheckCURP = false;
            break;
          case "CD":
            this.proofResidency = "";
            this.loadCheckProofResidency = false;
            break;
          case "CI":
            if (this.documentsArray.proofIncomes.length <= 0) {
              this.loadCheckProofIncome = false; 
            }
            break;
          case "EC":
            this.financialStatement = "";
            this.loadCheckFinancialStatement = false;
            break;
            case "RFCFIEL":
            this.rfc = "";
            this.loadCheckRFC = false;
            break;
          default:
            break;
        }
      },
      getFileNameFromURL(url, paramName) {
        var url = url
        var urlVar = url.split('&');
          for (var i = 0; i < urlVar.length; i++) {
            var param = urlVar[i].split('=');
            if (param[0] == paramName) {
              return param[1];
            }
          }
        return null;
      },
      getFileExtensionFromURL(url, paramName) {
        var url = url
        var urlVar = url.split('&');
          for (var i = 0; i < urlVar.length; i++) {
            var param = urlVar[i].split('=');
            if (param[0] == paramName) {
              return param[1];
            }
          }
        return null;
      },
      getFinalDocumentArray(document){
        let finalDocumentArray = []
        
        finalDocumentArray.push({
          file: document.file,
          document_name: document.name,
          business_acronym:"FONCABSA",
          process:"documentos_alta_socio",
          member_id: this.member_id,
          document_acronym: document.abbreviation,
          extension: document.extension
        });

        return finalDocumentArray;
      },
      getFinalDocumentDelete(document){
        let finalDocumentArray = []
        
        finalDocumentArray.push({
          document_name: document.name,
          member_id: this.member_id,
          business_acronym:"FONCABSA",
          document_id: document.id
        });

        return finalDocumentArray;
      },
      save(document_list) {
        this.isLoading = true
        let data = {
          token_auth: sessionStorage.getItem("login"),
          document_list: document_list
        };
        return this.axios.post(this.ccapi + `/${process.env.VUE_APP_BUSINESS_FONCABSA}/admission_request/upload_documents_for_registration`, data,{ 'Content-Type': 'application/json'})
            .then(response => {
              // console.log("Save: ",response);
              for(let i = 0 ; i < Object.values(document_list).length ; i++){
                this.nameDocumentsArray.push({name: document_list[i].name+'.'+document_list[i].extension ,
                  type: document_list[i].abbreviation});
              }
              // console.log("Save: ",response.data.response.remote_path_list);
              this.updateArrayDocumentsSelected(response.data.response.remote_path_list);
            }).catch(error => {
              console.log("ERROR: ",error);
              this.isLoading = false;
              Swal.fire({
                title: "Aviso",
                text: "Error al cargar documento",
                icon: "error",
                confirmButtonColor: '#FEB72B',
              })
            });
      },
      async loadInfo(){
        var auth = getTokenDecoden();
        this.personId = auth.obj.person['id'];
        this.user_id = auth["id"];
        //this.mati_identity_id = auth['mati_identity_id'];
      },

      setDocumentSelected(documents, isDocumentSaved) {
        // console.log("DOCUMENTS ",documents);
        // console.log();
        this.documentsArray = {
          identifications: [],
          documents: [],
          proofIncomes: []
        };
        for (let i = 0; i < documents.length; i++) {
          if (isDocumentSaved) {
            let document_name = this.getFileNameFromURL(documents[i].url, "document_name");
            var documentItem = {
              id: documents[i].document_id,
              typeDocument: documents[i].abbreviation,
              name: document_name,
              correctName: documents[i].document_name,
              file: "",
              document_name: "Documentos_Socios",
              business_acronym:"FONCABSA",
              process:"documentos_alta_socio",
              extension: "",
              abbreviation: documents[i].abbreviation
            }
          }

          switch (documents[i].abbreviation) {
            case "IO":
              if (isDocumentSaved) {
                this.loadCheckID = true;
                // console.log("setDocumentSelected2 ", this.loadCheckID);
                this.documentsArray.identifications.push(documentItem); 
              }
              break;
            case "CURP":
              this.curp = isDocumentSaved != true ? documents[i].document_name +"." +documents[i].abbreviation : documentItem.name;
              if (isDocumentSaved) {
                this.documentsArray.documents.push(documentItem);  
              }
              this.loadCheckCURP = true;
              break;
            case "CD":
              this.proofResidency = isDocumentSaved != true ? documents[i].document_name+"." +documents[i].abbreviation : documentItem.name;
              if (isDocumentSaved) {
                this.documentsArray.documents.push(documentItem);  
              }
              this.loadCheckProofResidency = true;
              break;
            case "CI":
              if (isDocumentSaved) {
                this.documentsArray.proofIncomes.push(documentItem);
              }
              this.loadCheckProofIncome = true;
              break;
            case "EC":
              this.financialStatement = isDocumentSaved != true ? documents[i].document_name+"." +documents[i].abbreviation : documentItem.name;
              if (isDocumentSaved) {
                this.documentsArray.documents.push(documentItem);  
              }
              this.loadCheckFinancialStatement = true;
              break;
            case "RFCFIEL":
              this.rfc = isDocumentSaved != true ? documents[i].document_name+"." +documents[i].abbreviation : documentItem.name;
              if (isDocumentSaved) {
                this.documentsArray.documents.push(documentItem);  
              }
              this.loadCheckRFC = true;
              break;
            default:
              break;
          }
        }
        this.validate();
        this.isLoading=false;
      },
      validate(){
        if (!this.loadCheckID || !this.loadCheckCURP || !this.loadCheckProofResidency) {
          this.isReady = false;
        } else {
          this.isReady = true;
        }

        // console.log("ready: ", this.isReady)
      },
      goToValidate(){
        // window.location.href = "/cuenta/registro-datos-personales"
        this.$router.push({path:"/cuenta/registro-datos-personales"});
      },
      goToMainPage(){
        if (!this.isFromMenu) {
          Swal.fire({
            title: "Aviso",
            text: "La opción para cargar sus documentos estará accesible desde el menú principal en la sección “Mis datos”",
            icon: "info",
            confirmButtonColor: '#FEB72B',
          }).then(function() {
            // window.location.href = "/cuenta/principal"
            this.$router.push({path:"/cuenta/principal"});
          });
        } else {
          // window.location.href = "/cuenta/principal"
          this.$router.push({path:"/cuenta/principal"});
        }
      },
      async endProcess(){
        // console.log(this.admission_status)
        var path2 = ""
        var infoJson = this.buildJson();
        if (this.admission_status == "info_beneficiarios_completa") {
          await this.saveRequest(infoJson).then(() => {
            path2 = "/cuenta/aviso-privacidad-autorizo-descuento";
          })
        }else{
          path2 = await this.redirectByAdmissionRequestStatus(this.admission_status);
        }
        this.$router.push({ path: path2 });
      },
      async saveRequest(jsonData){
        this.isLoading=true;
        jsonData.token_auth = sessionStorage.getItem("login");
        return await saveRequest(
          jsonData
        )
        .then((response ) => {
          this.setInfoToLocalStorage(`admissionUser${this.user_id}`, response);
        })
        .catch((error) => {
          Swal.fire({
            title: "Aviso",
            text: "Se presento un error al actualizar la información personal.",
            icon: "error",
            confirmButtonColor: '#FEB72B',
          })
        }).finally( () => {this.isLoading=false})
      },
      setInfoToLocalStorage(user_id, jsonPersonalInfo) {
        sessionStorage.setItem(user_id, JSON.stringify(jsonPersonalInfo));
      },
      getJsonFromLocalStorageBy(user_id) {
        return JSON.parse(window.sessionStorage.getItem(user_id));
      },
      async getAdmissionRequest() {
        this.isLoading = true;
        await getAdmissionRequestOrCreate(
          sessionStorage.getItem("login"),
          this.personId
          )
          .then((response) => {
            if (response.data.response.admission_request != null)
            {
              this.setInfoToLocalStorage(`admissionUser${this.user_id}`, response);
              this.admission_id = response.data.response.admission_request.id;
              this.client_classification = response.data.response.admission_request.admission_information.client_classification;
              this.verifyStatusAdmission(response);
            }
            else {
              //No hay solicitud registrada
              this.showMessage();
            }
          })
          .catch((error) => {
            this.isLoading=false;
            Swal.fire({
              title: "Aviso",
              text: "Se presentó un error al consultar las solicitudes",
              icon: "error",
              confirmButtonColor: '#FEB72B',
            });
          });
      },
      async showMessage(){
        this.isLoading=false;
        this.user_is_validated = false;
        await Swal.fire({
          title: "Aviso",
          text: "Para continuar con la carga de documentos favor de verificarse.",
          icon: "info",
          confirmButtonColor: '#FEB72B',
        }).then((result) => {
          if (result.value) {
            // window.location.href = "/cuenta/registro-datos-personales"
            this.$router.push({path:"/cuenta/registro-datos-personales"});
          }
        });
      },
      async verifyStatusAdmission(response) {
        this.admission_status = response.data.response.admission_request.status;  //se obtiene el estatus de la solicitud
        // console.log(this.admission_status)

        if (this.admission_status == "identidad_no_verificada"){
          if (this.isFromMenu){
              this.isLoading = false;
              await  Swal.fire({
                title: "Aviso",
                text: "Para continuar con la carga de documentos favor de verificar su identidad en pantalla completa registro.",
                icon: "warning",
                confirmButtonColor: '#FEB72B',
              });
              // window.location.href = "/cuenta/registro-datos-personales";
              this.$router.push({path:"/cuenta/registro-datos-personales"}); 
            }
            return;
        }
        
        //se consulta si la solicitud ya tiene una verificación
        await this.validateInfoDocuments();

      },
      async validateInfoDocuments(){
        await this.getDocuments();
        if(!this.loadCheckID){
          await this.getAndSaveIdentification();
        }
      },
      async getAndSaveIdentification() {
        await this.admissionRequestObject.getAndSaveIdentification(sessionStorage.getItem("login"), this.admission_id).finally(()=>{
          this.getDocuments();
        })
      },
      updateArrayDocumentsSelected(documents_list) {
        // console.log("documents_list: ",documents_list);
        for (let i = 0; i < documents_list.length; i++) {
          //let name = this.getFileNameFromURL(url, "document_name");
          var documentItem = {
            id: documents_list[i].id,
            typeDocument: documents_list[i].abbreviation,
            name: documents_list[i].name,
            correctName: documents_list[i].name,
            file: documents_list[i].remote_path,
            document_name: "Documentos_Socios",
            business_acronym:"FONCABSA",
            process:"documentos_alta_socio",
            extension: documents_list[i].extension,
            abbreviation: documents_list[i].abbreviation
          }

          switch (documents_list[i].abbreviation) {
            case "IO":
              this.loadCheckID = true;
              this.documentsArray.identifications.push(documentItem);
              break;
            case "CURP":
              this.curp = documentItem.name +"."+documentItem.extension;
              this.documentsArray.documents.push(documentItem);
              this.loadCheckCURP = true;
              break;
            case "CD":
              this.proofResidency = documentItem.name +"."+documentItem.extension ;
              this.documentsArray.documents.push(documentItem);
              this.loadCheckProofResidency = true;
              break;
            case "CI":
              this.documentsArray.proofIncomes.push(documentItem);
              this.loadCheckProofIncome = true;
              break;
            case "EC":
              this.financialStatement = documentItem.name+"." + documentItem.extension;
              this.documentsArray.documents.push(documentItem);
              this.loadCheckFinancialStatement = true;
              break;
            case "RFCFIEL":
              this.rfc = documentItem.name +".Delete" +documentItem.extension;
              this.documentsArray.documents.push(documentItem);
              this.loadCheckRFC = true;
              break;
            default:
              break;
          }
        }
        this.validate();
        // this.isLoading=false;
      },
      async redirectByAdmissionRequestStatus(status) {
        var path = "";
        switch (status) {
          case "info_personal_incompleta":
            path = "/cuenta/registro-datos-personales-dos";
            break;
          case "info_personal_completa": 
            path = "/cuenta/informacion-economica";
            break;
          case "info_economica_completa":
            if(['educator','businessman'].includes(this.client_classification)){
              path = "/cuenta/informacion-laboral";
            }else{
              path = "/cuenta/informacion-laboral-miembros";
            }
            break;
          case "info_laboral_completa":
            path = "/cuenta/ahorro";
            break;
          case "info_ahorro_completa": 
            path = "/cuenta/beneficiarios";
            break;
          case "carga_documento_completa": 
            path = "/cuenta/aviso-privacidad-autorizo-descuento";
            break;
          case "autorizo_descuento_aceptado": 
            path = "/cuenta/video/admission_request";
            break;
          case "video_completo": 
            path = "/terminos-y-condiciones";
            break;
        }
        return path;
      },
      buildJson() {
        let json = this.getJsonFromLocalStorageBy(`admissionUser${this.user_id}`);
        
        return {
          token_auth: sessionStorage.getItem("login"),
          admission_request_id: this.admission_id,
          user_id: this.user_id,
          change_for: "document_complete",
          admission_request_params: {
            admission_information: json.data.response.admission_request.admission_information,
          },
        };
      },
      backReedirect() {
        this.$router.push({ path: '/cuenta/beneficiarios' });
      },
      async havePendingDocuments(lengthDocuments){
        this.requestFinish = false;
        if(lengthDocuments < 10){
          if(["pendiente_de_revision","en_revision","aceptada"].includes(this.admission_status)){
            this.requestFinish = true;
          }
          
          this.isLoading = false;
        }else{
          //Se verifica el estatus de la solicitud
          switch (this.admission_status) {
            case "pendiente_de_revision":
              this.message = "La solicitud se encuentra en proceso de revisión, pronto será contactado por un asesor.";
              break;
            case "en_revision":
              this.message = "Usted ya ha concluido con el proceso de la carga de documentos, si quiere hacer algún cambio, favor de comunicarse a las oficinas de FONCABSA.";
              break;
            case "aceptada":
              this.message = "Usted ya es un cliente FONCABSA";
              break;
          }

          if(this.message!= null){
            this.isLoading = false;
            await  Swal.fire({
              title: "Aviso",
              text: this.message,
              icon: "warning",
            });
            // window.location.href = "/cuenta/principal";
            this.$router.push({path:"/cuenta/principal"});
          }
        }
      }
    }
}
</script>

<style scoped>
.register{
  border: 1px solid #999999;
  background-color: red;
  color: #666666;
}
.center {
  padding: 00px 0;
  border: 3px solid #ffffff;;
  text-align: center;
}

@media (min-width: 766px) {
  .collapse.dont-collapse-sm {
    display: block;
    height: auto !important;
    visibility: visible;
  }
}

#button_popups{
      border-top-left-radius: 15px;
      border-top-right-radius: 15px;
      border-bottom-left-radius: 15px;
      border-bottom-right-radius: 15px;
      background-color: #FEB72B;
      width: 250px;
      height: 30px;
      padding-top: 0%;
  }

button {
  outline: none !important;
}

h2 {font-size:36px;margin:0 0 10px 0}
p {margin:0 0 10px 0}
table.width200,table.rwd_auto {width:100%;margin:0 0 50px 0;}
  .width200 th,.rwd_auto th {background:#fff;padding:5px;text-align:left;}
  .width200 td,.rwd_auto td {padding:5px;text-align:left}
  .width200 tr:last-child td, .rwd_auto tr:last-child td{border:0;}
  .width200 thead tr {border-bottom:1px solid #ccc;color:#726659;font-size:16px !important;font-family:Roboto !important;font-weight: 400 !important;}
  .width200 tbody td {color:#726659;}

@media only screen and (max-width: 1024px)  
{
  table.width200, .width200 thead, .width200 tbody, .width200 th, .width200 td, .width200 tr { display: block; }
  .width200 thead tr { position: absolute;top: -9999px;left: -9999px; }
  .width200 tr { border-bottom: 1px solid #ccc; }
  .width200 td { position: relative;padding-left: 40%;text-align:left; }
  .width200 td:before {  position: absolute; top: 6px; left: 6px; width: 45%; padding-right: 10px; white-space: nowrap;}
  .width200 td:nth-of-type(1):before { 
    content: "Documento:"; 
    color: #726659;
    font-family:Roboto;
    font-weight: 700;
  }
  .width200 td:nth-of-type(2):before { 
    content: "Estatus:"; 
    color: #726659;
    font-family:Roboto;
    font-weight: 700;
  }
  .width200 td:nth-of-type(3):before { 
    content: "Nombre:"; 
    color: #726659;
    font-family:Roboto;
    font-weight: 700;
  }
  .width200 td:nth-of-type(4):before { content: ""; }
  .descarto {display:none;}
  .fontsize {font-size:10px;}
}

/* table.subtable,table.rwd_auto {width:100%;margin:0 0 50px 0;}
  .subtable th,.rwd_auto th {background:#fff;padding:5px;text-align:left;}
  .subtable td,.rwd_auto td {padding:5px;text-align:left}
  .subtable tr:last-child td, .rwd_auto tr:last-child td{border:0;}
  .subtable thead tr {border-bottom:1px solid #ccc;}
  .subtable tbody td {color:#8e8e8e;} */

@media only screen and (max-width: 1024px)  
{
  .subtable, .subtable thead, .subtable tbody, .subtable th, .subtable td, .subtable tr { display: block; }
  .subtable thead tr { position: absolute;top: -9999px;left: -9999px; }

  .subtableid td,.subtableid th {
    display: block;
    padding: 1rem;
    text-align:left;
  }
  
  .subtable td {position: relative;padding-left: 40%;text-align:left; }
  .subtable td:before {  position: absolute; top: 6px; left: 6px; width: 45%; padding-right: 10px; white-space: nowrap;}
  .subtable td:nth-of-type(1):before { content: ""; }
  .subtable td:nth-of-type(2):before { content: ""; }
  .subtable td:nth-of-type(3):before { content: ""; }
  .subtable td:nth-of-type(4):before { content: ""; }
  .descarto {display:none;}
  .fontsize {font-size:10px;}
}

@media only screen and (max-width: 1024px)  
{
  .subtableid, .subtableid thead, .subtableid tbody, .subtableid th, .subtableid td, .subtableid tr { display: block; }
  .subtableid thead tr { position: absolute;top: -9999px;left: -9999px; }
  
  .subtableid td {position: relative;padding-left: 40%;text-align:left; }
  .subtableid td:before {  position: absolute; top: 6px; left: 6px; width: 45%; padding-right: 10px; white-space: nowrap;}
  .subtableid td:nth-of-type(1):before { content: ""; }
  .subtableid td:nth-of-type(2):before { content: ""; }
  .subtableid td:nth-of-type(3):before { content: ""; }
  .subtableid td:nth-of-type(4):before { content: ""; }
  .descarto {display:none;}
  .fontsize {font-size:10px;}
}
/* Smartphones (portrait and landscape) ----------- */
@media only screen and (min-width : 320px) and (max-width : 480px) 
{
  .descarto {display:none;}
}
/* iPads (portrait and landscape) ----------- */
@media only screen and (min-width: 768px) and (max-width: 1024px) 
{
  .descarto {display:none;}
  .fontsize {font-size:10px}
}

.btnMain {
  text-decoration:none;
  font-family:Roboto;
  font-size: 13px;
  color:#8e8e8e;
  padding-top:5px;
  padding-bottom:5px;
  background-color:#FFF;
  border: 0px solid;
  border-color:#8e8e8e;
  border-width: 1px;border-radius:35px;
  width: 12em;
  outline:none;
  float:right;
  margin-right:15px;
}

.btnMainProofIncomeNoResponsive {
  text-decoration:none;
  font-family:Roboto;
  font-size: 13px;
  color:#8e8e8e;
  padding-top:5px;
  padding-bottom:5px;
  background-color:#FFF;
  border: 0px solid;
  border-color:#8e8e8e;
  border-width: 1px;border-radius:35px;
  width: 12em;
  outline:none;
  float:right;
  margin-right: 10px;
  visibility: visible;
}

.btnMainNoResponsive {
  text-decoration:none;
  font-family:Roboto;
  font-size: 13px;
  color:#8e8e8e;
  padding-top:5px;
  padding-bottom:5px;
  background-color:#FFF;
  border: 0px solid;
  border-color:#8e8e8e;
  border-width: 1px;border-radius:35px;
  width: 12em;
  outline:none;
  float:right;
  margin-right: 15px;
  visibility: visible;
}

.btnDeleteDocument {
  text-decoration:none;
  font-family:Roboto;
  font-size: 13px;
  color: red;
  padding-top:5px;
  padding-bottom:5px;
  background-color:#FFF;
  border: 0px solid;
  border-color:red;
  border-width: 1px;
  border-radius:35px;
  width: 12em;
  outline:none;
  float:right;
  margin-right:15px;
}

.btnLoadAfterDocuments {
  text-decoration:none;
  font-family:Roboto;
  font-size: 14px;
  font-weight: bold;
  color:#FEB72B ;
  padding-top:5px;
  padding-bottom:5px;
  background-color:#FFF;
  border: 0px solid;
  border-color:#FEB72B;
  border-width: 1px;
  border-radius:7px;
  height: 40px;
  width: 20em;
  outline:none;
}

.btnAddDocuments {
  text-decoration:none;
  font-family:Roboto;
  font-size: 14px;
  font-weight: bold;
  color:#FFF ;
  padding-top:5px;
  padding-bottom:5px;
  background-color:#FEB72B;
  border: 0px solid;
  border-color:#FEB72B;
  border-width: 1px;
  border-radius:7px;
  height:40px;
  width: 20em;
  outline:none;
}

@media (max-width: 500px) {
  .btnLoadAfterDocuments {
    height: 50px !important;
  }
  .btnAddDocuments {
    height: 50px !important;
  }
}

.btnFile {
	width: 0.1px;
	height: 0.1px;
	opacity: 0;
	overflow: hidden;
	position: absolute;
	z-index: -1;
  outline:none;
}

.btnFile + label {
  text-decoration:none;
  font-family:Roboto;
  font-size: 13px;
  color:#8e8e8e;
  padding-top:5px;
  padding-bottom:5px;
  background-color:#FFF;
  border: 0px solid;
  border-color:#8e8e8e;
  border-width: 1px;border-radius:35px;
  width: 12em;
  text-align: center;
}

.btnFile + label {
	cursor: pointer; /* "hand" cursor */
}

.table-scroll{
  width:100%;
  /* display: block; */
  empty-cells: show;
  /* Decoration */
  border-spacing: 0;
}

.table-scroll thead{
  position:relative;
  display: block;
  width:100%;
  overflow-y: scroll;
}

.table-scroll tbody{
  /* Position */
  display: block; position:relative;
  width:100%; overflow-y:scroll;
  /* Decoration */
}

.table-scroll tr{
  width: 100%;
  display:flex;
}

::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey; 
  border-radius: 10px;
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #8E8E8E; 
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #8E8E8E; 
}

.table-scroll td,.table-scroll th{
  /* flex-basis:100%; */
  display: block;
  padding: 1rem;
  text-align:left;
}

.table-scroll2 td,.table-scroll2 th{
  display: block;
  padding: 1rem;
  text-align:left;
}
/* Other options */

.table-scroll.small-first-col td:first-child,
.table-scroll.small-first-col th:first-child{
  flex-basis:20%;
  flex-grow:1;
}

.table-scroll tbody tr:nth-child(2n){
  
}

.body-half-screen{
  max-height: 20vh;
}

.small-col{}

.titlesProofIncome {
  word-wrap: break-word;
}

.titles {
  font-size:13px;
  font-family:Roboto;
  font-weight: 400;
  word-wrap: break-word;
}

.tdTable {
  height: 40px;
  padding-left: 0 !important;
}

.trTable {
  border-bottom: 1px solid #ccc;
  border-right: 1px solid #ccc;
  border-left: 1px solid #ccc;
  border-top: 1px solid #ccc;
  vertical-align:top;
  height:100px;
}
.trRFC {
  height:100px;
  vertical-align: middle;
  border-bottom: 1px solid #ccc;
}
.btnDeleteResponsive {
  visibility: hidden;
  float:left;
}

.btnDeleteNoResponsive {
  text-decoration:none;
  font-family:Roboto;
  font-size: 13px;
  color: red;
  padding-top:5px;
  padding-bottom:5px;
  background-color:#FFF;
  border: 0px solid;
  border-color:red;
  border-width: 1px;
  border-radius:35px;
  width: 12em;
  outline:none;
  visibility: visible;
  float:right;
}
.btnDeleteMobile {
  visibility: hidden;
  float:left;
}
.btnLoadMobile {
  visibility: hidden;
  float:left;
}
.textMati {
  color:#6ee5d3;
  font-weight:bold;
  text-align:center;
  float:right;
  margin-right:23px;
}
.tdButton {
  display:block;
}
@media only screen and (max-width: 1024px)  
{
  .trTable {
    height: 400px !important;
  }
  .trRFC {
    height:auto;
    vertical-align: middle;
    border-bottom: 1px solid #ccc;
  }
  .tdTable {
    height: auto;
    padding-left: 0px;
  }
  .btnDeleteResponsive {
    visibility: visible;
  }
  .btnDeleteNoResponsive {
    visibility: hidden;
  }
  .btnDeleteMobile {
    visibility: visible;
  }
  .btnLoadMobile {
    visibility: visible;
  }
  .btnMainNoResponsive {
    visibility: hidden;
  }
  .btnMainProofIncomeNoResponsive {
    visibility: hidden;
  }
  .body-half-screen{
    max-height: 30vh;
  }
  .btnDeleteResponsive {
    width:70px;
  }
  .btnMainProofIncomeNoResponsive {
    width:70px;
    float:none;
    margin-right: 0;
  }
  .btnMainNoResponsive{
    width:70px;
    float:none;
    margin-right: 0;
  }
  .btnDeleteDocument {
    width: 5em;
    float: none;
    margin-right: 0;
  }
  .titlesProofIncome {
    width: 300px;
  }
  .titles{
    width: 300px;
  }
  .textMati {
    text-align:left;
    float:none;
    margin-right:0;
  }
  .checkmark {
    margin: 0 !important;
  }
  .tdButton {
    display:none !important;
  }
}

@media only screen and (max-width: 630px)  
{
  .titlesProofIncome {
    width: 100px; 
  }
  .titles{
    width: 200px;
  }
}

@media only screen and (max-width: 630px)  
{
  .titlesProofIncome {
    width: 100px; 
  }
  .titles{
    width: 100px;
  }
}

#progress_bar {
  margin: 10px 0;
  padding: 3px;
  border: 1px solid #000;
  font-size: 14px;
  clear: both;
  opacity: 0;
  -moz-transition: opacity 1s linear;
  -o-transition: opacity 1s linear;
  -webkit-transition: opacity 1s linear;
}
#progress_bar.loading {
  opacity: 1.0;
}
#progress_bar .percent {
  background-color: #99ccff;
  height: auto;
  width: 0;
}

@media (max-width: 501px) {

  .next_button, .end_process, .back_button {
      width: 100% !important;
  }
}
</style>